<template>
   <main id="territorio-screen" class="main">
      
      <div class="breadcrumb">
         <div class="text">Home <span class="slash">/</span> Territorio</div>
      </div>

      <div class="container">
         
         <div v-html="main_content"></div>

      </div>
   </main>
</template>

<script>
import { Utils } from '@/inc/Utils';

export default{
   name: 'Territorio',
   mixins: [Utils],
   data(){
      return {
         main_content: '<div class="d-flex align-items-center justify-content-center py-3"><i class="fa fa-spinner rotating"></i></div>',
      }
   },
   methods: {
   },
   created(){
      var vm = this;
      if( this.contents.territorio ){
         this.main_content = this.contents.territorio;
      }      
      this.get_app_content( 'territorio' ).then( (content) => {
         vm.main_content = content;
      });
   }
}
</script>
<style scoped>
</style>